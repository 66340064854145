import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { sessionSelector } from '../../state/reducers/session';
import { useQuery } from 'react-query';
import { UserSettingsGeneralProps } from './user-settings-general.props';
import { MyGeneralSettings } from '@myneva-portals/models/src/models/my-organization/my-general-settings';
import {
  UserSettingsGeneralContent,
  UserSettingsGeneralViewContent,
} from '@myneva-portals/projects/src/components';
import { ErrorTimeout, LoadingWrapper } from 'front-components/src/components';
import { navigate } from 'gatsby';
import { navigationSelector } from '../../state/reducers/navigation';
import breadcrumbsUtils from '@myneva-portals/utils/src/utils/breadcrumbs-utils';
import { restSettingsService } from '../../services/settings-service/rest-settings-service';

export const UserSettingsGeneral: React.FC<UserSettingsGeneralProps> = (
  props: UserSettingsGeneralProps
) => {
  const [content, setContent] = React.useState<JSX.Element>(null);
  const { t } = useTranslation();
  const { breadcrumbs } = useSelector(navigationSelector);

  const customBreadCrumbConfig = [
    {
      breadcrumbName: `${t('USER-SETTINGS/GENERAL')} (${t(
        'USER-SETTINGS/GENERAL/EDIT'
      )})`,
      path: '/user-settings/general',
    },
  ];

  const { userUuid } = useSelector(sessionSelector);

  const { isLoading, error, data, refetch } = useQuery(
    'getMySettingsQuery',
    () => {
      return restSettingsService
        .getMySettings(userUuid)
        .then((data: MyGeneralSettings) => data);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const [isEditMode, setIsEditMode] = React.useState(props.isEdit);

  useEffect(() => {
    setContent(getModuleContent(isLoading, data, error));
  }, [data, isEditMode]);

  const getModuleContent = (
    isLoading: boolean,
    data: MyGeneralSettings,
    error: unknown
  ) => {
    if (!isLoading) {
      return error ? (
        <div>error</div>
      ) : isEditMode ? (
        <UserSettingsGeneralContent
          {...data}
          uuid={userUuid}
          breadcrumbs={breadcrumbsUtils.getBreadcrumbs(
            breadcrumbs,
            customBreadCrumbConfig
          )}
          changePageMode={() => {
            if (props.backLocation) {
              navigate(props.backLocation);
            }
            setIsEditMode(false);
            refetch();
          }}
          onSubmit={(values, uuid) =>
            restSettingsService.setMyGeneralSettings(values, uuid)
          }
          translation={t}
        />
      ) : (
        <UserSettingsGeneralViewContent
          {...data}
          breadcrumbs={breadcrumbsUtils.getBreadcrumbs(breadcrumbs)}
          changePageMode={() => {
            setIsEditMode(true);
          }}
          translation={t}
        />
      );
    }
  };

  const errorComponent = (
    <ErrorTimeout
      header={t('TIMEOUT_ERROR')}
      text={t('TIMEOUT_ERROR_MSG')}
      buttonLabel={t('REFETCH_BUTTON_LABEL')}
      onButtonClick={() => refetch()}
    />
  );

  return (
    <div>
      <LoadingWrapper
        isLoading={isLoading}
        content={error ? errorComponent : content}
        useLineProgress={true}
      />
    </div>
  );
};
