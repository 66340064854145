import * as React from 'react';

import 'front-components/src/styles/colorPallete.css';
import 'front-components/src/styles/fonts.css';
import 'front-components/src/styles/spacing.css';

import { PortalLayout } from 'front-components/src/components';

import { AdminMenu } from '../../components/asside-menu/asside-menu';
import { PortalHeader } from '../../components/portal-header/portal-header';
import { MenuType } from '@myneva-portals/models/src/models/navigation/menu-type';
import { UserSettingsGeneral } from '../../content/user-settings/user-settings-general';

const GeneralPage = ({ location }) => {
  return (
    <main>
      <title>General Settings Page</title>
      <PortalLayout
        header={<PortalHeader />}
        asside={<AdminMenu type={MenuType.USER_SETTINGS_MENU} />}
      >
        <UserSettingsGeneral
          isEdit={location.state?.isEdit}
          backLocation={location.state?.backLocation}
        />
      </PortalLayout>
    </main>
  );
};

export default GeneralPage;
